/**
 * desc: 用于重构的AylNewTabel组件分页+表单查询
 * author: suyonggang
 */
export default {
    data() {
        return {
            loading: false,
            current: 1,
            totalNum: 0,
            currentPage: 1,
            pageSize: 10,
        }
    },
    mounted() {
        this.getListData();
    },

    methods: {
        // 搜索参数监听
        getSearchPramas(val) {
            this.current = 1;
            this.currentPage = 1;
            this.tableData.query = val;
            this.getListData();
        },
        handleSizeChange(val) {
            this.current = 1;
            this.currentPage = 1;
            this.pageSize = val;
            this.getListData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getListData();
        },
        onSearch() {
            this.current = 1;
            this.currentPage = 1;
            this.getListData()
        },
        getListData() {
            let page = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
            };
            let pramas = Object.assign(this.tableData.query, page);
            this.loading = true;
            this.tableData
                .api(pramas)
                .then((res) => {
                    this.tableData.data = res.data;
                    this.totalNum = res.totalNum;
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
}